import { Button } from "react-bootstrap";
import useIosInstallPrompt from "./useIosInstallPrompt";
import useWebInstallPrompt from "./useWebInstallPrompt";

export const InstallPWA = () => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }
  return (
    <>
      {iosInstallPrompt && (
        <div className="pwa-install-modal">
          <img className="logo" src="/logo.png" alt="Icon" />
          <div className="content-block">
            <h3>Install App</h3>
            <>
              <p className="my-2">
                Tap
                <img
                  src="/ios-action.svg"
                  style={{ margin: "auto 8px 8px" }}
                  className=""
                  alt="Add to homescreen"
                  width="20"
                />
                then &quot;Add to Home Screen&quot;
              </p>
            </>
          </div>
          <div className="modal-action">
            <Button variant="light" onClick={handleIOSInstallDeclined}>
              x
            </Button>
          </div>
        </div>
      )}
      {webInstallPrompt && (
        <div className="pwa-install-modal">
          <img className="logo" src="/logo.png" alt="Icon" />
          <div className="content-block">
            <h3>Install App</h3>
          </div>
          <div className="modal-action">
            <Button variant="primary" onClick={handleWebInstallAccepted}>
              Install
            </Button>
            <Button
              className="ms-1"
              variant="light"
              onClick={handleWebInstallDeclined}
            >
              x
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
