import React from "react";
import { Container, Button } from "react-bootstrap";
import fbIcon from "../../assets/images/fb_black.svg";
import instaIcon from "../../assets/images/instagram.png";
import twitterIcon from "../../assets/images/twitter_black.svg";
import themeToggleIcon from "../../assets/images/theme_switch.png";
import searchIcon from "../../assets/images/search.svg";
import cartIcon from "../../assets/images/cart-black.svg";

export default function TopHeader() {
  return (
    <div className="top-header">
      <Container>
        <div className="top-header-container">
          <div className="left-col">
            <ul className="social-media-list">
              <li>
                <a
                  href="https://www.facebook.com/enterprisejournal.newspaper"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fbIcon} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/TheEJlive"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitterIcon} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/enterprisejournal/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instaIcon} alt="" />
                </a>
              </li>
            </ul>
            <button className="btn theme-btn">
              <img src={themeToggleIcon} alt="" />
            </button>
          </div>
          <div className="right-col">
            <a href="/" className="btn auth-link">
              Sign Up
            </a>
            <Button href="/" variant="dark">
              Log in
            </Button>
            <button className="btn search-btn">
              <img src={searchIcon} alt="" />
            </button>
            <button className="btn cart-btn">
              <img src={cartIcon} alt="" />
              <span className="count">0</span>
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
}
