import React from "react";
import { Container } from "react-bootstrap";
import bannerImg from "../../assets/images/EJ_logo_0.png";

export default function HeaderBanner() {
  return (
    <div className="header-banner">
      <Container>
        <a href="/">
          <img src={bannerImg} alt="" />
        </a>
      </Container>
    </div>
  );
}
