import React, { useState } from "react";
import { Nav, Navbar, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import addBtnIcon from "../../assets/images/add_wht.png";

export default function HeaderNav() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar expand="lg" expanded={expanded}>
      <div className="nav-item-container">
        <div className="nav-toggle">
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          />
          <span className="txt">Menu</span>
        </div>
        <Button variant="link" className="add-post-btn">
          <img src={addBtnIcon} alt="" />
          Post
        </Button>
      </div>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav onClick={() => setExpanded(false)} className="me-auto">
          <Nav.Link exact as={NavLink} to="/">
            Home
          </Nav.Link>
          <Nav.Link as={NavLink} to="/top-stories">
            Top Stories
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
