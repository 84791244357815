import React from "react";
import { Container } from "react-bootstrap";

export default function Banner(props) {
  return (
    <div className="banner">
      <Container>
        <a href={props.url}>
          <img src={props.poster} alt="" />
        </a>
      </Container>
    </div>
  );
}
