import React from "react";

import TopHeader from "./topHeader";
import Banner from "../banner";
import HeaderBanner from "./headerBanner";
import HeaderNav from "./headerNav";

export default function Header() {
  return (
    <header className="page-header">
      <Banner
        url="/"
        poster="https://street-production.s3.amazonaws.com/assets/ca58ac16-b39e-402c-9881-92c245280d19.jpg"
      />
      <TopHeader />
      <div className="nav-header-container">
        <HeaderBanner />
        <HeaderNav />
      </div>
    </header>
  );
}
