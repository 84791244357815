
import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
   
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss'
import Header from "./components/header/header";
import { InstallPWA } from "./InstallPrompt/installPWA";

const HomePage = lazy(() => import('./components/homepage'))
const TopStories = lazy(() => import('./components/topStories'))
const NewsDetails = lazy(() => import('./components/newsDetails'))



function App() {

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>} >
      <InstallPWA/>
        <Header/>
      <div className="page-container">
        <Switch>
          <Route exact  path="/"  component={HomePage}/>
          <Route exact path="/top-stories"  component={TopStories}/>
          <Route exact path="/news-details"  component={NewsDetails}/>

        </Switch>
      </div>
      </Suspense>
    </Router>
  );
}

export default App;
